import React, { ReactNode } from 'react';
import SEO from '../core/meta/SEO';
import { graphql, Link, PageProps, useStaticQuery } from 'gatsby';
import { Container } from '../core/layout/Layout';
import Heading from '../core/typography/Heading';
import BackgroundImage from 'gatsby-background-image';
import {
  ContentfulLayout1,
  Layout1Query,
} from '../../../generated/graphql-types';
import {
  renderOptions,
  renderOptionsLowMarginHeaders,
} from '../../contentful/render-options';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import FaqAccordion from '../views/FaqAccordion/FaqAccordion';
import ResponsiveList from '../views/ResponsiveList/ResponsiveList';
import Button from '../core/buttons/Button';
import AboutUs from '../partials/AboutUs/AboutUs';
import PartnersList from '../partials/PartnersList/PartnersList';
import OtherServices from '../views/OtherServices/OtherServices';
import useDatalayer from '../../../hooks/use-datalayer';
import PromoBanner2 from '../partials/PromoBanner/PromoBanner2';
import { useAnalytics } from '../../analytics/AnalyticsProvider';
import { getSrc } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';

const Layout1 = ({
  pageContext: { pageData },
}: PageProps<unknown, { pageData: Partial<ContentfulLayout1> }>) => {
  const layout1 = useStaticQuery<Layout1Query>(graphql`
    query Layout1 {
      banner: file(relativePath: { eq: "banner.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const datalayer = useDatalayer();
  const analytics = useAnalytics();

  return (
    <>
      <SEO
        title={pageData.metaTitle}
        description={pageData.metaDescription}
        metaImage={pageData.headerBackground}
        hidePageSuffix
        url={`/${pageData.slug}`}
      />
      {pageData.headerBackground && (
        <BackgroundImage
          {...convertToBgImage(pageData.headerBackground.gatsbyImageData)}
        >
          <div
            className="bg-black bg-opacity-60 py-16 flex"
            style={{ minHeight: '460px' }}
          >
            <Container className="flex flex-col justify-center">
              <Heading
                variant="h1"
                className="text-center"
                style={{ color: '#ffffff' }}
              >
                {pageData.title}
              </Heading>
              {pageData.headerCtaText && pageData.headerCtaText && (
                <div className="flex justify-center">
                  <Link to={pageData.headerCtaLink}>
                    <Button
                      variant="filled"
                      color="primary"
                      onClick={() => {
                        datalayer.triggerContentfulDatalayerAction(
                          pageData.headerCtaDatalayerAction
                        );
                        if (pageData.headerCtaEvent) {
                          analytics.submitEvent(
                            pageData.headerCtaEvent as any,
                            {
                              location: pageData.headerCtaEventLocation as any,
                            }
                          );
                        }
                      }}
                    >
                      {pageData.headerCtaText}
                    </Button>
                  </Link>
                </div>
              )}
            </Container>
          </div>
        </BackgroundImage>
      )}
      <div className="py-16">
        {pageData.introText && (
          <Container className="mb-16 text-center">
            {/*//@ts-ignore*/}
            {renderRichText(pageData.introText, renderOptionsLowMarginHeaders)}
          </Container>
        )}
        <Container className="mb-16">
          <div className="-mx-5">
            <ResponsiveList
              sliderProps={{
                navOutside: true,
                darkControls: true,
              }}
              classNameGridItemSetting={`md:w-1/3 px-10 py-4`}
            >
              {pageData.text1 && pageData.image1 && (
                <div>
                  <div style={{ height: '256px' }}>
                    <div
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                      className="flex justify-center"
                    >
                      <img src={getSrc(pageData.image1.gatsbyImageData)} />
                    </div>
                  </div>

                  <div className="text-center">
                    {renderRichText(
                      // @ts-ignore
                      pageData.text1,
                      renderOptionsLowMarginHeaders
                    )}
                  </div>
                </div>
              )}
              {pageData.text2 && pageData.image2 && (
                <div>
                  <div style={{ height: '256px' }}>
                    <div
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                      className="flex justify-center"
                    >
                      <img src={getSrc(pageData.image2.gatsbyImageData)} />
                    </div>
                  </div>

                  <div className="text-center">
                    {renderRichText(
                      // @ts-ignore
                      pageData.text2,
                      renderOptionsLowMarginHeaders
                    )}
                  </div>
                </div>
              )}
              {pageData.text3 && pageData.image3 && (
                <div>
                  <div style={{ height: '256px' }}>
                    <div
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                      className="flex justify-center"
                    >
                      <img src={getSrc(pageData.image3.gatsbyImageData)} />
                    </div>
                  </div>

                  <div className="text-center">
                    {renderRichText(
                      // @ts-ignore
                      pageData.text3,
                      renderOptionsLowMarginHeaders
                    )}
                  </div>
                </div>
              )}
            </ResponsiveList>
          </div>
        </Container>
        {((pageData.featureImage1 &&
          pageData.featureImage2 &&
          pageData.featureText1 &&
          pageData.featureText2) ||
          pageData.featureText3 ||
          pageData.featureText4) && (
          <div className="bg-primary-300 py-16 pb-20">
            <Container>
              {pageData.featureSectionTitle && (
                <Heading variant="h2" className="text-center">
                  {pageData.featureSectionTitle}
                </Heading>
              )}

              <div className="flex flex-wrap -mx-4">
                {pageData.featureImage1 && pageData.featureText1 && (
                  <div className="w-full flex md:w-1/2 px-4 mb-10 md:mb-0">
                    <div className="rounded-card shadow-card bg-white p-8">
                      <div style={{ height: '256px' }}>
                        <div
                          style={{ maxWidth: '100%', maxHeight: '100%' }}
                          className="flex justify-center"
                        >
                          <img
                            src={getSrc(pageData.featureImage1.gatsbyImageData)}
                          />
                        </div>
                      </div>
                      <div className="text-center">
                        {renderRichText(
                          //@ts-ignore
                          pageData.featureText1,
                          renderOptionsLowMarginHeaders
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {pageData.featureImage2 && pageData.featureText2 && (
                  <div className="w-full flex md:w-1/2 px-4">
                    <div className="rounded-card shadow-card bg-white p-8">
                      <div style={{ height: '256px' }}>
                        <div
                          style={{ maxWidth: '100%', maxHeight: '100%' }}
                          className="flex justify-center"
                        >
                          <img
                            src={getSrc(pageData.featureImage2.gatsbyImageData)}
                          />
                        </div>
                      </div>
                      <div className="text-center">
                        {renderRichText(
                          //@ts-ignore
                          pageData.featureText2,
                          renderOptionsLowMarginHeaders
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {(pageData.featureText3 || pageData.featureText4) && (
                <div className="flex flex-col">
                  {pageData.featureText3 && (
                    <div
                      className={`rounded-card shadow-card bg-white p-8 ${
                        pageData.featureText4 && 'mb-6'
                      }`}
                    >
                      {renderRichText(
                        //@ts-ignore
                        pageData.featureText3,
                        renderOptionsLowMarginHeaders
                      )}
                    </div>
                  )}
                  {pageData.featureText4 && (
                    <div className="rounded-card shadow-card bg-white p-8">
                      {renderRichText(
                        //@ts-ignore
                        pageData.featureText4,
                        renderOptionsLowMarginHeaders
                      )}
                    </div>
                  )}
                </div>
              )}
            </Container>
          </div>
        )}
        {pageData.middleText && (
          <Container className="py-16">
            {/*//@ts-ignore*/}
            {renderRichText(pageData.middleText, renderOptionsLowMarginHeaders)}
          </Container>
        )}
        <div className="relative">
          <div
            className="absolute bottom-0 transform translate-y-16"
            style={{
              height: '120px',
              width: '100%',
              backgroundImage: `url('${getSrc(
                layout1.banner.childImageSharp.gatsbyImageData
              )}')`,
              backgroundRepeat: 'repeat-x',
              backgroundSize: 'contain',
            }}
          />
          {pageData.bodyCta1Url && pageData.bodyCta1Text && (
            <div className="flex justify-center mb-16 z-10 relative">
              <a href={pageData.bodyCta1Url}>
                <Button
                  variant="filled"
                  color="primary"
                  onClick={() => {
                    datalayer.triggerContentfulDatalayerAction(
                      pageData.bodyCta1DatalayerAction
                    );

                    if (pageData.bodyCta1Event) {
                      analytics.submitEvent(pageData.bodyCta1Event as any, {
                        location: pageData.bodyCta1EventLocation as any,
                      });
                    }
                  }}
                >
                  {pageData.bodyCta1Text}
                </Button>
              </a>
            </div>
          )}
        </div>
        {/*<PromoBanner1*/}
        {/*  background={*/}
        {/*    pageData.pricingBlock1 || pageData.pricingBlock2*/}
        {/*      ? tw`bg-primary-300`*/}
        {/*      : tw`bg-white`*/}
        {/*  }*/}
        {/*/>*/}
        <PromoBanner2 />

        {(pageData.pricingBlock1 || pageData.pricingBlock2) && (
          <div className="bg-primary-300 pb-20 relative">
            <div
              className="absolute bottom-0 md:bottom-10"
              style={{
                height: '120px',
                width: '100%',
                backgroundImage: `url('${getSrc(
                  layout1.banner.childImageSharp.gatsbyImageData
                )}')`,
                backgroundRepeat: 'repeat-x',
                backgroundSize: 'contain',
              }}
            />
            <Container className="z-10 relative">
              <div className="flex flex-wrap -mx-4 pt-20">
                {pageData.pricingBlock1 && (
                  <div className="w-full flex md:w-1/2 px-4 mb-10 md:mb-0">
                    <div className="rounded-card shadow-card bg-white p-8 flex flex-col">
                      <div className="mb-auto">
                        {renderRichText(
                          //@ts-ignore
                          pageData.pricingBlock1,
                          renderOptionsLowMarginHeaders
                        )}
                      </div>
                      {pageData.pricing1CtaText && pageData.pricing1CtaUrl && (
                        <div className="flex justify-center">
                          <a href={pageData.pricing1CtaUrl}>
                            <Button
                              variant="filled"
                              color="primary"
                              onClick={() => {
                                datalayer.triggerContentfulDatalayerAction(
                                  pageData.pricing1CtaDatalayerAction
                                );
                                if (pageData.pricing1CtaEvent) {
                                  analytics.submitEvent(
                                    pageData.pricing1CtaEvent as any,
                                    {
                                      location: pageData.pricing1CtaEventLocation as any,
                                    }
                                  );
                                }
                              }}
                            >
                              {pageData.pricing1CtaText}
                            </Button>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {pageData.pricingBlock2 && (
                  <div className="w-full flex md:w-1/2 px-4">
                    <div className="rounded-card shadow-card bg-white p-8 flex flex-col">
                      <div className="mb-auto">
                        {renderRichText(
                          //@ts-ignore
                          pageData.pricingBlock2,
                          renderOptionsLowMarginHeaders
                        )}
                      </div>
                      {pageData.pricing2CtaText && pageData.pricing2CtaUrl && (
                        <div className="flex justify-center">
                          <a href={pageData.pricing2CtaUrl}>
                            <Button
                              variant="filled"
                              color="primary"
                              onClick={() => {
                                datalayer.triggerContentfulDatalayerAction(
                                  pageData.pricing2CtaDatalayerAction
                                );
                                if (pageData.pricing2CtaEvent) {
                                  analytics.submitEvent(
                                    pageData.pricing2CtaEvent as any,
                                    {
                                      location: pageData.pricing2CtaEventLocation as any,
                                    }
                                  );
                                }
                              }}
                            >
                              {pageData.pricing2CtaText}
                            </Button>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Container>
          </div>
        )}
        {pageData.partnersText && (
          <Container className="py-6">
            <div>
              {/*@ts-ignore*/}
              {renderRichText(pageData.partnersText, renderOptions)}
            </div>
            <PartnersList />
          </Container>
        )}
        {pageData.spacerImage && (
          <BackgroundImage
            className="hidden md:block"
            {...convertToBgImage(pageData.spacerImage.gatsbyImageData)}
            style={{ height: '650px' }}
          />
        )}
        {pageData.faqItems?.length && (
          <Container className="pt-16">
            <Heading variant="h2">Domande frequenti</Heading>
            <FaqAccordion
              faqItems={pageData.faqItems.map(({ answer, question }) => ({
                answer: renderRichText(
                  // @ts-ignore
                  answer,
                  renderOptions
                ) as ReactNode,
                question: question.question,
              }))}
            />
          </Container>
        )}
      </div>
      {pageData.showTestimonial && (
        <div className="py-16 md:py-20 bg-primary-300">
          <Container>
            <AboutUs />
          </Container>
        </div>
      )}
      {pageData?.showOtherServicesLinks?.length ? (
        <Container className="pt-20 pb-16">
          <OtherServices
            compagnia={
              pageData.showOtherServicesLinks.indexOf('compagnia') > -1
            }
            commissioni={
              pageData.showOtherServicesLinks.indexOf('commissioni') > -1
            }
            accompagnamento={
              pageData.showOtherServicesLinks.indexOf('accompagnamento') > -1
            }
          />
        </Container>
      ) : null}
    </>
  );
};

export default Layout1;
